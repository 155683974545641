import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import ActivityV3Entry from '@/components/activityV3/activityV3-entry.vue';
import ActivityV2Entry from '@/components/gpg/activityV2-entry.vue';
import Cookies from 'js-cookie';
import { AccessToken } from '@/api/pan/member';
let PageAll = class PageAll extends Vue {
    constructor() {
        super(...arguments);
        this.eqcAuthToken = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdXRoX21ldGhvZCI6ImF1dGgtYnktcGFzc3dvcmQiLCJleHBpcmUiOjE5NjgzODc4MzUsIm9iamVjdF9pZCI6IjBkMTM1MWNlLTY4NTYtNDllMC1iOTAwLTI1NzM2M2UxNjVjOCIsInRva2VuMSI6IjdmMmQ2NTc5LWI0YjUtNDlhOC05YmYxLWEwYmU3YWE0YzU3YyIsInRva2VuMiI6IjZkYzJlMTQxLTE3ZGMtNDRhNC1iN2E3LTgxM2MxYzNlZGIwYSIsInVpZCI6MjE4NDk4N30.PEhSFvXQaW_5GTUifraDy-2JwEjHKY3OPr7N5wpkx2gmzpYsPXdzLB_uWnQ21foWsGJcSTEjoohnZZEPkKv2vA';
        this.honeyAuthToken = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdXRoX21ldGhvZCI6ImF1dGgtYnktcGFzc3dvcmQiLCJleHBpcmUiOjE5NTExOTk0ODcsIm9iamVjdF9pZCI6IjY3MmNkNWQ2LTBjNGMtNDg5MC05YzQ1LTU3NTY3MWMyM2IwMyIsInRva2VuMSI6IjdmMmQ2NTc5LWI0YjUtNDlhOC05YmYxLWEwYmU3YWE0YzU3YyIsInRva2VuMiI6IjMwNmEyNzRkLThjNDctNDE5Ny1iM2I2LWZlMjcxMWUxZmE1MSIsInVpZCI6NDg5OTM2MX0.pM3mMMc5xXWVtbaO6JKdUGJxdWW1iF1K4TubSOLp3bJ8qoAesFPRLzK6-obe7_du15yCdRjIecuhbmCzy3fkQQ';
        this.ladyObjectId = '672cd5d6-0c4c-4890-9c45-575671c23b03';
        this.accessToken = '';
        this.w = 500;
        this.h = window.screen.height;
        this.ids = [1, 3, 5, 13, 16, 19, 22, 26, 31, 34, 37, 40];
    }
    async notlogin() {
        const yes = confirm('你還沒登入, 是否要以草莓Honey身份登入?');
        if (yes) {
            this.setAuthTokenAshoney();
            await this.fetchAccessToken();
            this.reload();
        }
        else {
            alert('你取消了');
        }
    }
    notvalidatemobile() {
        alert('not validate mobile');
    }
    setAuthTokenAseqc() {
        Cookies.set('nekothtua', this.eqcAuthToken, { expires: 365 });
    }
    setAuthTokenAshoney() {
        Cookies.set('nekothtua', this.honeyAuthToken, { expires: 365 });
    }
    setEqc005() {
        Cookies.set('nekothtua', 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHBpcmUiOjE5MjE4MzIzMTYsIm9iamVjdF9pZCI6IiIsInRva2VuMSI6IjdmMmQ2NTc5LWI0YjUtNDlhOC05YmYxLWEwYmU3YWE0YzU3YyIsInRva2VuMiI6IiIsInVpZCI6NDM0MzkzN30.JKv62AmASd4mmBiQy84xOmuMW67gT95qHNcR3l8RkCKmWijtr_eQqqx0gUR5NfeUa6DVySJJtTfhpZcIYulB5Q', { expires: 365 });
    }
    clearAuthToken() {
        Cookies.remove('nekothtua');
    }
    reload() { }
    async beforeMount() {
        localStorage.setItem('gubed', 'true');
        await this.fetchAccessToken();
    }
    async fetchAccessToken() {
        this.accessToken = await AccessToken(Cookies.get('nekothtua') || '').then((res) => res.getAccessToken());
    }
};
PageAll = __decorate([
    Component({
        components: {
            ActivityV2Entry,
            ActivityV3Entry,
        },
    })
], PageAll);
export default PageAll;
